import React from "react";

import config from "../config.json";
import { DevtoolsLoading } from "../templates/devtools-info";

export const Login: React.FC = () => {
  window.location.href = config.OAUTH_URL;

  DevtoolsLoading();

  return <></>;
};
