import React, { useEffect, useState } from "react";
import { AGuild } from "../types";
import axios from "axios";
import "react-notifications-component/dist/theme.css";
import {
  NOTIFICATION_TYPE,
  ReactNotifications,
  Store,
} from "react-notifications-component";

import config from "../config.json";
import { Loading } from "../templates/Loading";
import { DevtoolsLoading } from "../templates/devtools-info";

export const Admin: React.FC = () => {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState<boolean>(true);
  const [aguilds, setAGuilds] = useState<Array<AGuild> | null>(null);
  const accessToken = localStorage.getItem("access_token");

  axios.defaults.headers.common["Authorization"] = accessToken;

  document.title = "Laden...";

  function send_notification(
    title: string,
    message: string,
    type: NOTIFICATION_TYPE,
  ) {
    Store.addNotification({
      title: title,
      message: message,
      type: type,
      insert: "top",
      container: "bottom-right",
      animationIn: ["animate__animated animate__fadeInLeft"],
      animationOut: ["animate__animated animate__fadeOutRight"],
      dismiss: {
        duration: 5000,
        showIcon: true,
        pauseOnHover: true,
        onScreen: true,
      },
    });
  }

  useEffect(() => {
    const makeRequests = async () => {
      if (accessToken) {
        const userRES = await axios.post(`${config.API_URL}/users/me`);

        if (userRES.data["id"] === "743059390713561159") {
          setLoading(false);

          const guildsRES = await axios.post(`${config.API_URL}/admin/guilds`);
          setAGuilds(guildsRES.data);
        } else {
          window.location.href = "/";
        }
      } else {
        window.location.href = "/";
      }
    };
    makeRequests();
  }, []);

  if (loading) {
    return <Loading />;
  }

  DevtoolsLoading();

  function sendAnnouncement(e: any) {
    e.preventDefault();
    axios.post(`${config.API_URL}/admin/send_announcement`, {
      title: title,
      message: message,
    })
      .then((response) => {
        send_notification(
          "Nachricht gesendet",
          "Deine Nachricht wurde erfolgreich",
          "success",
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function leaveServer(guild: AGuild) {
    axios.post(`${config.API_URL}/admin/leave_guild`, {
      guild_id: guild.id,
    })
      .then((response) => {
        if (response.data["success"] === true) {
          send_notification(
            "Erfolg",
            `Der Bot hat den Server "${guild.name}" verlassen!`,
            "success",
          );

          axios.post(`${config.API_URL}/admin/guilds`)
            .then((resp) => {
              setAGuilds(resp.data);
            });
        } else {
          send_notification(
            "Fehler",
            `Der Bot konnte den Server "${guild.name}" nicht verlassen!`,
            "danger",
          );
        }
      });
  }

  function banServer(guild: AGuild) {
    axios.post(`${config.API_URL}/admin/ban_guild`, {
      guild_id: guild.id,
    })
      .then((response) => {
        if (response.data["success"] === true) {
          send_notification(
            "Erfolg",
            `Der Server "${guild.name}" ist nun aus dem System ausgeschlossen!`,
            "success",
          );

          axios.post(`${config.API_URL}/admin/guilds`)
            .then((resp) => {
              setAGuilds(resp.data);
            });
        } else {
          send_notification(
            "Fehler",
            `Der Bot konnte den Server "${guild.name}" nicht aus dem System ausschließen!`,
            "danger",
          );
        }
      });
  }

  document.title = "Admin Panel - JBackup";
  return (
    <div>
      <div>
        <ReactNotifications />
        <div className="container mx-auto">
          <div className="grid grid-cols-2">
            <div className="flex mt-10 justify-start items-center">
              <div>
                <div
                  className="transition duration-500 transform bg-gray-700 hover:bg-gray-600 rounded p-2 mr-3 flex items-center cursor-pointer"
                  onClick={() => window.location.href = "/"}
                >
                  <div>
                    <img
                      src={"https://cdn.discordapp.com/app-icons/1008785680241000498/cb6092329acb3301018dadea7017c9f2.png?size=256"}
                      alt="Logo"
                      className="rounded-full mr-3 border-2 border-white"
                      width={40}
                    />
                  </div>
                  <div>
                    <h1 className="text-2xl text-white">
                      JBackup
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-center mt-20">
            <div className="w-9/12">
              <div className="grid place-items-center">
                <h1 className="text-white text-4xl">
                  Admin Panel
                </h1>
              </div>

              <div className="bg-gray-700 shadow-md rounded px-8 pt-6 pb-8 mb-4 mt-5 w-full">
                <h1 className="block text-white text-sm font-bold text-xl mb-5">
                  Benachrichtigung Senden
                </h1>

                <form
                  onSubmit={(e) => sendAnnouncement(e)}
                >
                  <div className="mb-4">
                    <label className="block text-white text-sm font-bold mb-2">
                      Titel
                    </label>
                    <input
                      className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 text-white bg-gray-600 leading-tight focus:outline-none"
                      type="text"
                      placeholder="Changelog"
                      required
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                  <div className="mb-6">
                    <label className="block text-white text-sm font-bold mb-2">
                      Nachricht
                    </label>
                    <textarea
                      className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 text-white bg-gray-600 leading-tight focus:outline-none"
                      required
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </div>
                  <div className="flex items-center justify-between">
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                      Senden
                    </button>
                  </div>
                </form>
              </div>

              <div className="bg-gray-700 p-10 mt-10 rounded mb-10 pt-6">
                <h1 className="block text-white text-sm font-bold text-xl mb-5">
                  Server
                </h1>
                <div className="h-70 grid grid-cols-4 gap-10">
                  {aguilds?.map((aguild: AGuild) => {
                    return (
                      <div className="transition duration-500 transform bg-gray-600 rounded text-white mb-4">
                        <div className="flex justify-center">
                          <img
                            className="rounded-full m-5 mb-3 border-white border-2"
                            width={150}
                            src={aguild.icon_url}
                            alt={aguild.name}
                          />
                        </div>
                        <div className="px-6 py-4">
                          <h1 className="word-break text-center text-gray-400">
                            {aguild.id}
                          </h1>
                          <h1 className="text-l word-break text-center">
                            {aguild.name}
                          </h1>
                          <h1 className="text-l word-break text-center">
                            {aguild.owner}
                          </h1>
                        </div>

                        <div className="flex justify-center mb-5">
                          <div className="items-center">
                            <button
                              className="transition duration-500 transform bg-red-500 hover:bg-red-600 text-white p-1 rounded"
                              onClick={() => {
                                leaveServer(aguild);
                              }}
                            >
                              verlassen
                            </button>
                            <button
                              className="transition duration-500 transform bg-red-500 hover:bg-red-600 text-white p-1 rounded ml-1"
                              onClick={() => {
                                banServer(aguild);
                              }}
                            >
                              bannen
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
