import React, { useEffect, useState } from "react";
import axios from "axios";
import { Status, User } from "../types";
import devtools from "devtools-detect";

import config from "../config.json";
import { Loading } from "../templates/Loading";
import { DevtoolsLoading } from "../templates/devtools-info";

export const Homepage: React.FC = () => {
  document.title = "Laden...";

  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [status, setStatus] = useState<Status | null>(null);
  const accessToken = localStorage.getItem("access_token");

  axios.defaults.headers.common["Authorization"] = accessToken;

  useEffect(() => {
    axios.get(`${config.API_URL}/status`)
      .then((resp) => {
        setStatus(resp.data);
      });

    if (accessToken) {
      axios.post(`${config.API_URL}/users/me`, {
        access_token: accessToken,
      }).then((response) => {
        const user: User = response.data;
        setUser(user);
        setLoading(false);
      }).catch((e) => setLoading(false));
    } else {
      setLoading(false);
    }
  }, []);

  document.title = "Home - JBackup";

  if (loading) {
    return <Loading />;
  }

  DevtoolsLoading();

  return (
    <div>
      <title>JBackup</title>
      <div className="container mx-auto md:container md:mx-auto text-center h-screen">
        {!user
          ? (
            <div className="grid grid-cols-2">
              <div className="flex mt-10 justify-start items-center">
                <div>
                  <div
                    className="transition duration-500 transform bg-gray-700 hover:bg-gray-600 rounded p-2 mr-3 flex items-center cursor-pointer"
                    onClick={() => window.location.href = "/"}
                  >
                    <div>
                      <img
                        src={"https://cdn.discordapp.com/app-icons/1008785680241000498/cb6092329acb3301018dadea7017c9f2.png?size=256"}
                        alt="Logo"
                        className="rounded-full mr-3 border-2 border-white"
                        width={40}
                      />
                    </div>
                    <div>
                      <h1 className="text-2xl text-white">
                        JBackup
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex mt-10 justify-end items-center">
                <div>
                  <button
                    className="transition duration-500 transform bg-blue-500 hover:bg-blue-600 text-white text-xl p-1 px-2 rounded"
                    onClick={() => window.location.href = "/login"}
                  >
                    Einloggen mit Discord
                  </button>
                </div>
              </div>
            </div>
          )
          : (
            <div className="grid grid-cols-2">
              <div className="flex mt-10 justify-start items-center">
                <div>
                  <div
                    className="transition duration-500 transform bg-gray-700 hover:bg-gray-600 rounded p-2 mr-3 flex items-center cursor-pointer"
                    onClick={() => window.location.href = "/"}
                  >
                    <div>
                      <img
                        src={"https://cdn.discordapp.com/app-icons/1008785680241000498/cb6092329acb3301018dadea7017c9f2.png?size=256"}
                        alt="Logo"
                        className="rounded-full mr-3 border-2 border-white"
                        width={40}
                      />
                    </div>
                    <div>
                      <h1 className="text-2xl text-white">
                        JBackup
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex mt-10 justify-end items-center">
                <div>
                  <div className="rounded p-2 bg-gray-700 mr-3 flex items-center">
                    <div>
                      <img
                        src={user?.avatar_url}
                        alt="User Avatar"
                        className="rounded-full mr-3 border-2 border-white"
                        width={40}
                      />
                    </div>
                    <div>
                      <h1 className="text-2xl text-white">
                        {user?.username}#{user?.discriminator}
                      </h1>
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    className="transition duration-500 transform bg-red-500 hover:bg-red-600 text-white p-1 rounded mr-3"
                    onClick={() => window.location.href = "/logout"}
                  >
                    Logout
                  </button>
                </div>
                <div>
                  <button
                    className="transition duration-500 transform bg-blue-500 hover:bg-blue-600 text-white p-1 rounded mr-3"
                    onClick={() => window.location.href = "/backups"}
                  >
                    Backups
                  </button>
                </div>
                <div>
                  <button
                    className="transition duration-500 transform bg-blue-500 hover:bg-blue-600 text-white p-1 rounded"
                    onClick={() => window.location.href = "/guilds"}
                  >
                    Server
                  </button>
                </div>
              </div>
            </div>
          )}

        <div className="flex h-full justify-center items-center">
          <div className="container mx-auto">
            <img
              src={"https://cdn.discordapp.com/app-icons/1008785680241000498/cb6092329acb3301018dadea7017c9f2.png?size=256"}
              alt="BOT PNG"
              className="inline mb-5 rounded-full border-4 border-white"
            />
            <h1 className="text-white text-3xl mb-5 text-semibold">
              Einfache Backups für deinen Discord Server.
            </h1>
            <div className="flex justify-center items-center">
              <div>
                <button
                  className="transition duration-500 transform bg-blue-500 hover:bg-blue-600 text-white text-3xl p-3 px-4 rounded mr-5"
                  onClick={() => window.location.href = config.INVITE_URL}
                >
                  Einladen
                </button>
              </div>
              <div>
                <button
                  className="transition duration-500 transform bg-blue-500 hover:bg-blue-600 text-white text-3xl p-3 px-4 rounded"
                  onClick={() => window.location.href = "/docs"}
                >
                  Docs
                </button>
              </div>
            </div>
          </div>
        </div>
        <div>
        </div>
      </div>
      <div
        className="container mx-auto xl:w-2/6 lg:1/4 md:1/4 sm:1/4"
        id="status"
      >
        <h1 className="text-white font-semibold text-4xl text-center mb-8">
          Information
        </h1>
        <div className="flex items-center justify-between">
          <div>
            <h1 className="text-white font-semibold text-2xl text-center mb-3">
              Ping
            </h1>
            <h1 className="text-center text-white font-bold text-xl">
              {status ? status.ping + " ms" : "N/A"}
            </h1>
          </div>
          <div>
            <h1 className="text-white font-semibold text-2xl text-center mb-3">
              Status
            </h1>
            <span
              className={`bg-${
                status ? "green" : "red"
              }-500 text-white font-bold py-2 px-4 rounded py-2`}
            >
              {status ? "Online" : "Offline"}
            </span>
          </div>
          <div>
            <h1 className="text-white font-semibold text-2xl text-center mb-3">
              Server
            </h1>
            <h1 className="text-center text-white font-bold text-xl">
              {status ? status.guilds : "N/A"}
            </h1>
          </div>
        </div>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#3b82f6"
          fill-opacity="1"
          d="M0,224L34.3,202.7C68.6,181,137,139,206,112C274.3,85,343,75,411,85.3C480,96,549,128,617,149.3C685.7,171,754,181,823,170.7C891.4,160,960,128,1029,144C1097.1,160,1166,224,1234,234.7C1302.9,245,1371,203,1406,181.3L1440,160L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"
        >
        </path>
      </svg>
    </div>
  );
};
