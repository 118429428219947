import React, { useEffect, useState } from "react";
import { Backup, User } from "../types";
import axios from "axios";
import "react-notifications-component/dist/theme.css";
import {
  NOTIFICATION_TYPE,
  ReactNotifications,
  Store,
} from "react-notifications-component";

import config from "../config.json";
import { Loading } from "../templates/Loading";
import { DevtoolsLoading } from "../templates/devtools-info";

export const Backups: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [backups, setBackups] = useState<Array<Backup> | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingbackups, setLoadingbackups] = useState<boolean>(true);
  const accessToken = localStorage.getItem("access_token");

  axios.defaults.headers.common["Authorization"] = accessToken;

  document.title = "Laden...";

  function send_notification(
    title: string,
    message: string,
    type: NOTIFICATION_TYPE,
  ) {
    Store.addNotification({
      title: title,
      message: message,
      type: type,
      insert: "top",
      container: "bottom-right",
      animationIn: ["animate__animated animate__fadeInLeft"],
      animationOut: ["animate__animated animate__fadeOutRight"],
      dismiss: {
        duration: 5000,
        showIcon: true,
        pauseOnHover: true,
        onScreen: true,
      },
    });
  }

  useEffect(() => {
    const makeRequests = async () => {
      if (accessToken) {
        const userRES = await axios.post(`${config.API_URL}/users/me`);
        setUser(userRES.data);
        setLoading(false);

        await new Promise((r) => setTimeout(r, 2000));

        const backups = await axios.post(`${config.API_URL}/backup/list`);
        if (backups.data["success"] === false) {
          send_notification(
            "Fehler",
            "Deine Backups konnten nicht geladen werden!",
            "danger",
          );
        } else {
          setBackups(backups.data["backups"]);
          setLoadingbackups(false);
        }
      } else {
        window.location.href = "/";
      }
    };

    makeRequests();
  }, []);

  function reload_backups() {
    axios.post(`${config.API_URL}/backup/list`)
      .then((resp) => {
        if (resp.data["success"] === false) {
          send_notification(
            "Fehler",
            "Deine Backups konnten nicht geladen werden!",
            "danger",
          );
        } else {
          setBackups(resp.data["backups"]);
          setLoadingbackups(false);
        }
      });
  }

  function delete_backup(backup_name: string) {
    var check = window.confirm("Möchtest du das Backup löschen?");
    if (check === true) {
      axios.post(`${config.API_URL}/backup/delete`, {
        backup_name: backup_name,
      })
        .then((response) => {
          if (response.data["success"] === false) {
            send_notification(
              "Fehler",
              "Das Backup konnte nicht gelöscht werden!",
              "danger",
            );
          } else {
            send_notification(
              "Backup gelöscht",
              "Das Backup wurde erfolgreich gelöscht!",
              "success",
            );
            reload_backups();
          }
        });
    }
  }

  function delete_all_backups() {
    var check = window.confirm("Möchtest alle Backups löschen?");
    if (check === true) {
      axios.post(`${config.API_URL}/backup/delete_all`)
        .then((response) => {
          if (response.data["success"] === false) {
            send_notification(
              "Fehler",
              "Die Backups konnten nicht gelöscht werden!",
              "danger",
            );
          } else {
            send_notification(
              "Backups gelöscht",
              "Deine Backups wurden erfolgreich gelöscht!",
              "success",
            );
            reload_backups();
          }
        });
    }
  }
  if (loading) {
    return <Loading />;
  }

  DevtoolsLoading();

  if (loadingbackups) {
    return (
      <div>
        <div className="">
          <ReactNotifications />
          <div className="container mx-auto">
            <div className="grid grid-cols-2">
              <div className="flex mt-10 justify-start items-center">
                <div>
                  <div
                    className="transition duration-500 transform bg-gray-700 hover:bg-gray-600 rounded p-2 mr-3 flex items-center cursor-pointer"
                    onClick={() => window.location.href = "/"}
                  >
                    <div>
                      <img
                        src={"https://cdn.discordapp.com/app-icons/1008785680241000498/cb6092329acb3301018dadea7017c9f2.png?size=256"}
                        alt="Logo"
                        className="rounded-full mr-3 border-2 border-white"
                        width={40}
                      />
                    </div>
                    <div>
                      <h1 className="text-2xl text-white">
                        JBackup
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex mt-10 justify-end items-center">
                <div>
                  <div className="rounded p-2 bg-gray-700 mr-3 flex items-center">
                    <div>
                      <img
                        src={user?.avatar_url}
                        alt="User Avatar"
                        className="rounded-full mr-3 border-2 border-white"
                        width={40}
                      />
                    </div>
                    <div>
                      <h1 className="text-2xl text-white">
                        {user?.username}#{user?.discriminator}
                      </h1>
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    className="transition duration-500 transform bg-red-500 hover:bg-red-600 text-white p-1 rounded mr-3"
                    onClick={() => window.location.href = "/logout"}
                  >
                    Logout
                  </button>
                </div>
                <div>
                  <button
                    className="transition duration-500 transform bg-blue-500 hover:bg-blue-600 text-white p-1 rounded mr-3"
                    onClick={() => window.location.href = "/backups"}
                  >
                    Backups
                  </button>
                </div>
                <div>
                  <button
                    className="transition duration-500 transform bg-blue-500 hover:bg-blue-600 text-white p-1 rounded"
                    onClick={() => window.location.href = "/guilds"}
                  >
                    Server
                  </button>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-center mt-20">
              <div className="h-3/4">
                <div className="grid place-items-center">
                  <h1 className="text-white text-4xl">
                    Backups
                  </h1>
                  <div className="h-7 w-40 bg-gray-400 rounded mb-5 mt-4 animate-pulse">
                  </div>
                </div>
                <div className="pt-10 pb-16 h-64 grid grid-cols-3 gap-10">
                  <div className="bg-gray-700 rounded text-white animate-pulse">
                    <div className="flex justify-center">
                      <div className="px-6 py-6">
                        <div className="flex justify-center">
                          <div className="h-5 w-40 bg-gray-400 rounded mb-2">
                          </div>
                        </div>

                        <div className="flex items-center justify-center">
                          <div className="h-3 w-48 bg-gray-400 rounded mb-3">
                          </div>
                        </div>

                        <div>
                          <div className="flex items-center mb-2">
                            <div className="mr-3">
                              <div className="h-5 w-28 bg-gray-400 rounded">
                              </div>
                            </div>

                            <div>
                              <div className="h-16 w-16 rounded-full bg-gray-400">
                              </div>
                            </div>
                          </div>
                          <div className="mb-5">
                            <div className="flex items-center">
                              <div className="mr-3">
                                <div className="h-5 w-10 bg-gray-400 rounded mb-1">
                                </div>
                              </div>
                              <div>
                                <div className="h-3 w-20 bg-gray-400 rounded mb-1">
                                </div>
                              </div>
                            </div>

                            <div className="flex items-center">
                              <div className="mr-3">
                                <div className="h-5 w-20 bg-gray-400 rounded mb-1 mt-1">
                                </div>
                              </div>
                              <div>
                                <div className="h-3 w-20 bg-gray-400 rounded mb-1">
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="mb-5">
                            <div className="text-2xl mb-3 h-7 w-20 bg-gray-400 rounded">
                            </div>

                            <div className="flex items-center">
                              <div className="mr-3">
                                <div className="h-5 w-24 bg-gray-400 rounded mb-1 mt-1">
                                </div>
                              </div>
                              <div>
                                <div className="h-3 w-5 bg-gray-400 rounded mb-1">
                                </div>
                              </div>
                            </div>

                            <div className="flex items-center">
                              <div className="mr-3">
                                <div className="h-5 w-32 bg-gray-400 rounded mb-1 mt-1">
                                </div>
                              </div>
                              <div>
                                <div className="h-3 w-5 bg-gray-400 rounded mb-1">
                                </div>
                              </div>
                            </div>

                            <div className="flex items-center">
                              <div className="mr-3">
                                <div className="h-5 w-24 bg-gray-400 rounded mb-1 mt-1">
                                </div>
                              </div>
                              <div>
                                <div className="h-3 w-5 bg-gray-400 rounded mb-1">
                                </div>
                              </div>
                            </div>
                          </div>

                          <div>
                            <div className="h-7 w-14 bg-gray-400 rounded"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-700 rounded text-white animate-pulse">
                    <div className="flex justify-center">
                      <div className="px-6 py-6">
                        <div className="flex justify-center">
                          <div className="h-5 w-40 bg-gray-400 rounded mb-2">
                          </div>
                        </div>

                        <div className="flex items-center justify-center">
                          <div className="h-3 w-48 bg-gray-400 rounded mb-3">
                          </div>
                        </div>

                        <div>
                          <div className="flex items-center mb-2">
                            <div className="mr-3">
                              <div className="h-5 w-28 bg-gray-400 rounded">
                              </div>
                            </div>

                            <div>
                              <div className="h-16 w-16 rounded-full bg-gray-400">
                              </div>
                            </div>
                          </div>
                          <div className="mb-5">
                            <div className="flex items-center">
                              <div className="mr-3">
                                <div className="h-5 w-10 bg-gray-400 rounded mb-1">
                                </div>
                              </div>
                              <div>
                                <div className="h-3 w-20 bg-gray-400 rounded mb-1">
                                </div>
                              </div>
                            </div>

                            <div className="flex items-center">
                              <div className="mr-3">
                                <div className="h-5 w-20 bg-gray-400 rounded mb-1 mt-1">
                                </div>
                              </div>
                              <div>
                                <div className="h-3 w-20 bg-gray-400 rounded mb-1">
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="mb-5">
                            <div className="text-2xl mb-3 h-7 w-20 bg-gray-400 rounded">
                            </div>

                            <div className="flex items-center">
                              <div className="mr-3">
                                <div className="h-5 w-24 bg-gray-400 rounded mb-1 mt-1">
                                </div>
                              </div>
                              <div>
                                <div className="h-3 w-5 bg-gray-400 rounded mb-1">
                                </div>
                              </div>
                            </div>

                            <div className="flex items-center">
                              <div className="mr-3">
                                <div className="h-5 w-32 bg-gray-400 rounded mb-1 mt-1">
                                </div>
                              </div>
                              <div>
                                <div className="h-3 w-5 bg-gray-400 rounded mb-1">
                                </div>
                              </div>
                            </div>

                            <div className="flex items-center">
                              <div className="mr-3">
                                <div className="h-5 w-24 bg-gray-400 rounded mb-1 mt-1">
                                </div>
                              </div>
                              <div>
                                <div className="h-3 w-5 bg-gray-400 rounded mb-1">
                                </div>
                              </div>
                            </div>
                          </div>

                          <div>
                            <div className="h-7 w-14 bg-gray-400 rounded"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-700 rounded text-white animate-pulse">
                    <div className="flex justify-center">
                      <div className="px-6 py-6">
                        <div className="flex justify-center">
                          <div className="h-5 w-40 bg-gray-400 rounded mb-2">
                          </div>
                        </div>

                        <div className="flex items-center justify-center">
                          <div className="h-3 w-48 bg-gray-400 rounded mb-3">
                          </div>
                        </div>

                        <div>
                          <div className="flex items-center mb-2">
                            <div className="mr-3">
                              <div className="h-5 w-28 bg-gray-400 rounded">
                              </div>
                            </div>

                            <div>
                              <div className="h-16 w-16 rounded-full bg-gray-400">
                              </div>
                            </div>
                          </div>
                          <div className="mb-5">
                            <div className="flex items-center">
                              <div className="mr-3">
                                <div className="h-5 w-10 bg-gray-400 rounded mb-1">
                                </div>
                              </div>
                              <div>
                                <div className="h-3 w-20 bg-gray-400 rounded mb-1">
                                </div>
                              </div>
                            </div>

                            <div className="flex items-center">
                              <div className="mr-3">
                                <div className="h-5 w-20 bg-gray-400 rounded mb-1 mt-1">
                                </div>
                              </div>
                              <div>
                                <div className="h-3 w-20 bg-gray-400 rounded mb-1">
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="mb-5">
                            <div className="text-2xl mb-3 h-7 w-20 bg-gray-400 rounded">
                            </div>

                            <div className="flex items-center">
                              <div className="mr-3">
                                <div className="h-5 w-24 bg-gray-400 rounded mb-1 mt-1">
                                </div>
                              </div>
                              <div>
                                <div className="h-3 w-5 bg-gray-400 rounded mb-1">
                                </div>
                              </div>
                            </div>

                            <div className="flex items-center">
                              <div className="mr-3">
                                <div className="h-5 w-32 bg-gray-400 rounded mb-1 mt-1">
                                </div>
                              </div>
                              <div>
                                <div className="h-3 w-5 bg-gray-400 rounded mb-1">
                                </div>
                              </div>
                            </div>

                            <div className="flex items-center">
                              <div className="mr-3">
                                <div className="h-5 w-24 bg-gray-400 rounded mb-1 mt-1">
                                </div>
                              </div>
                              <div>
                                <div className="h-3 w-5 bg-gray-400 rounded mb-1">
                                </div>
                              </div>
                            </div>
                          </div>

                          <div>
                            <div className="h-7 w-14 bg-gray-400 rounded"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  document.title = "Backups - JBackup";
  return (
    <div>
      <div>
        <ReactNotifications />
        <div className="container mx-auto">
          <div className="grid grid-cols-2">
            <div className="flex mt-10 justify-start items-center">
              <div>
                <div
                  className="transition duration-500 transform bg-gray-700 hover:bg-gray-600 rounded p-2 mr-3 flex items-center cursor-pointer"
                  onClick={() => window.location.href = "/"}
                >
                  <div>
                    <img
                      src={"https://cdn.discordapp.com/app-icons/1008785680241000498/cb6092329acb3301018dadea7017c9f2.png?size=256"}
                      alt="Logo"
                      className="rounded-full mr-3 border-2 border-white"
                      width={40}
                    />
                  </div>
                  <div>
                    <h1 className="text-2xl text-white">
                      JBackup
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex mt-10 justify-end items-center">
              <div>
                <div className="rounded p-2 bg-gray-700 mr-3 flex items-center">
                  <div>
                    <img
                      src={user?.avatar_url}
                      alt="User Avatar"
                      className="rounded-full mr-3 border-2 border-white"
                      width={40}
                    />
                  </div>
                  <div>
                    <h1 className="text-2xl text-white">
                      {user?.username}#{user?.discriminator}
                    </h1>
                  </div>
                </div>
              </div>
              <div>
                <button
                  className="transition duration-500 transform bg-red-500 hover:bg-red-600 text-white p-1 rounded mr-3"
                  onClick={() => window.location.href = "/logout"}
                >
                  Logout
                </button>
              </div>
              <div>
                <button
                  className="transition duration-500 transform bg-blue-500 hover:bg-blue-600 text-white p-1 rounded mr-3"
                  onClick={() => window.location.href = "/backups"}
                >
                  Backups
                </button>
              </div>
              <div>
                <button
                  className="transition duration-500 transform bg-blue-500 hover:bg-blue-600 text-white p-1 rounded"
                  onClick={() => window.location.href = "/guilds"}
                >
                  Server
                </button>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-center mt-20">
            <div className="h-3/4">
              <div className="grid place-items-center">
                <h1 className="text-white text-4xl">
                  Backups
                </h1>
                {!backups ? <div></div> : (
                  <button
                    className="transition duration-500 transform bg-red-500 hover:bg-red-600 text-white p-1 rounded mb-5 mt-4"
                    onClick={() => {
                      delete_all_backups();
                    }}
                  >
                    Alle Backups löschen
                  </button>
                )}
              </div>

              {backups ? <div></div> : (
                <div className="justify-center mt-20">
                  <h1 className="text-white text-2xl mb-5 text-center">
                    gähnende Leere
                  </h1>
                  <a href="/docs#backup_erstellen">
                    <h1 className="text-white text-2xl mb-5 text-center transition duration-500 hover:text-gray-400">
                      Backup erstellen
                    </h1>
                  </a>
                </div>
              )}
              <div className="pt-10 pb-16 h-64 grid grid-cols-3 gap-10">
                {backups?.map((backup: Backup) => {
                  return (
                    <div className="transition duration-500 transform bg-gray-700 rounded text-white">
                      <div className="flex justify-center">
                        <div className="px-6 py-6">
                          <h1 className="text-2xl word-break text-center">
                            {backup.backup_name}
                          </h1>
                          <h1 className="text-gray-400 text-l word-break text-center mb-3">
                            {backup.backup_timestamp} |{" "}
                            {backup.backup_size_format}
                          </h1>

                          <div>
                            <div className="flex items-center">
                              <div className="mr-3">
                                <h2 className="text-2xl">
                                  {backup.guild_name}
                                </h2>
                              </div>

                              <div>
                                {backup.guild_icon_url
                                  ? (
                                    <img
                                      className="rounded-full mt-2 border-white border-2"
                                      width={50}
                                      src={backup.guild_icon_url}
                                      alt=""
                                    />
                                  )
                                  : <div></div>}
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="flex items-center">
                                <div className="mr-3">
                                  <h1 className="text-xl">ID</h1>
                                </div>
                                <div>
                                  <h2>{backup.guild_id}</h2>
                                </div>
                              </div>

                              <div className="flex items-center">
                                <div className="mr-3">
                                  <h1 className="text-xl">Besitzer</h1>
                                </div>
                                <div>
                                  <h2>{backup.guild_owner}</h2>
                                </div>
                              </div>
                            </div>

                            <div className="mb-5">
                              <h1 className="text-2xl mb-3">Gesichert</h1>

                              <div className="flex items-center">
                                <div className="mr-3">
                                  <h1 className="text-xl">Text Kanäle</h1>
                                </div>
                                <div>
                                  <h2>{backup.backup_text_channels}</h2>
                                </div>
                              </div>

                              <div className="flex items-center">
                                <div className="mr-3">
                                  <h1 className="text-xl">Sprach Kanäle</h1>
                                </div>
                                <div>
                                  <h2>{backup.backup_voice_channels}</h2>
                                </div>
                              </div>

                              <div className="flex items-center">
                                <div className="mr-3">
                                  <h1 className="text-xl">Kategorien</h1>
                                </div>
                                <div>
                                  <h2>{backup.backup_category_channels}</h2>
                                </div>
                              </div>
                            </div>

                            <div>
                              <button
                                className="transition duration-500 transform bg-red-500 hover:bg-red-600 text-white p-1 rounded"
                                onClick={() => {
                                  delete_backup(backup.backup_name);
                                }}
                              >
                                Löschen
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
