import React, { useEffect } from "react";
import queryString from "query-string";
import axios from "axios";
import config from "../config.json";
import { DevtoolsLoading } from "../templates/devtools-info";

interface TokenResponse {
  access_token: string | null;
}

export const CallBackHandler: React.FC = (props: any) => {

  const code = queryString.parse(window.location.search).code;
  const error = queryString.parse(window.location.search).error;

  useEffect(() => {
    if (!error) {
      axios.post(`${config.API_URL}/oauth/callback`, { code })
        .then((response) => {
          const data: TokenResponse = response.data;
          if (data.access_token === null) {
            window.location.href = "/login";
          } else {
            localStorage.setItem("access_token", data.access_token);
            window.location.href = "/";
          }
        });
    } else {
      window.location.href = "/";
    }
  }, []);

  DevtoolsLoading();

  return (
    <div className="h-screen flex items-center justify-center">
      <h1 className="text-white text-5xl">Weiterleitung...</h1>
    </div>
  );
};
