import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Admin } from './pages/Admin';
import { Backups } from './pages/Backups';
import { CallBackHandler } from './pages/CallBackHandler'
import { Docs } from './pages/Docs';
import { Homepage } from './pages/Homepage';
import { Login } from './pages/Login';
import { Logout } from './pages/Logout';
import { ShowGuilds } from './pages/ShowGuilds';

export const Router: React.FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={ <Homepage /> }/>
                <Route path="/login" element={ <Login /> }/>
                <Route path="/logout" element={ <Logout /> }/>
                <Route path="/callback" element={ <CallBackHandler /> }/>
                <Route path="/docs" element={ <Docs /> }/>
                <Route path="/guilds" element={ <ShowGuilds /> }/>
                <Route path="/backups" element={ <Backups /> }/>
                <Route path="/admin" element={ <Admin /> }/>
            </Routes>
        </BrowserRouter>
    )
}