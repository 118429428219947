import React from "react";
import devtools from "devtools-detect";

export function DevtoolsLoading() {
  function info_devtools() {
    console.log(
      "%cWarte!",
      "text-shadow: 0 0 2px #000; color: red; font-size: 6em;",
    );
    console.log(
      "%cFalls dich jemand dazu aufgefordert hat, etwas zu kopieren und hier einzufügen, handelt es sich in 11 von 10 Fällen um einen Betrugsversuch.",
      "color: white; font-size: 2em;",
    );
    console.log(
      "%cEtwas hier einzufügen könnte Angreifern Zugriff auf einen Teil deines Discord-Accounts geben.",
      "color: yellow; font-size: 2em;",
    );
    console.log(
      "%cWenn du nicht ganz genau weißt, was du da tust, dann schließe das Fenster und bleib auf der sicheren Seite.",
      "color: white; font-size: 2em;",
    );
  }

  window.addEventListener("blur", (e) => {
    e.preventDefault();
    info_devtools();
  });

  if (devtools.isOpen) {
    info_devtools();
  }
}
