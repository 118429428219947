import React from 'react';
import queryString from "query-string";
import axios from 'axios';
import config from "../config.json";
import { DevtoolsLoading } from '../templates/devtools-info';

export const Logout: React.FC = () => {
    const site = queryString.parse(window.location.search).site;
    const accessToken = localStorage.getItem("access_token");

    axios.defaults.headers.common['Authorization'] = accessToken;

    axios.post(`${config.API_URL}/oauth/revoke`)
          .then((resp) => {
            localStorage.removeItem("access_token");
            if (site) {
                window.location.href = `/${site}`;
            } else {
                window.location.href = `/`;
            }
          });

    DevtoolsLoading();

    return (
        <div>

        </div>
    )
}