import React, { useEffect, useState } from "react";
import { Guild, User } from "../types";
import axios from "axios";
import "react-notifications-component/dist/theme.css";
import {
  NOTIFICATION_TYPE,
  ReactNotifications,
  Store,
} from "react-notifications-component";

import config from "../config.json";
import { Loading } from "../templates/Loading";
import { DevtoolsLoading } from "../templates/devtools-info";

export const ShowGuilds: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [guilds, setGuilds] = useState<Array<Guild> | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingguilds, setLoadingguilds] = useState<boolean>(true);

  const accessToken = localStorage.getItem("access_token");

  axios.defaults.headers.common["Authorization"] = accessToken;

  document.title = "Laden...";

  function send_notification(
    title: string,
    message: string,
    type: NOTIFICATION_TYPE,
  ) {
    Store.addNotification({
      title: title,
      message: message,
      type: type,
      insert: "top",
      container: "bottom-right",
      animationIn: ["animate__animated animate__fadeInLeft"],
      animationOut: ["animate__animated animate__fadeOutRight"],
      dismiss: {
        duration: 5000,
        showIcon: true,
        pauseOnHover: true,
        onScreen: true,
      },
    });
  }

  useEffect(() => {
    const makeRequests = async () => {
      if (accessToken) {
        const userRES = await axios.post(`${config.API_URL}/users/me`);
        setUser(userRES.data);
        setLoading(false);

        await new Promise((r) => setTimeout(r, 2000));

        const guildsRES = await axios.post(`${config.API_URL}/users/me/guilds`);
        setGuilds(guildsRES.data.guilds);
        setLoadingguilds(false);
      } else {
        window.location.href = "/";
      }
    };

    makeRequests();
  }, []);

  function create_backup(server_id: number) {
    var backup_name = prompt("Gebe einen Namen für das Backup ein:");

    if (backup_name) {
      axios.post(`${config.API_URL}/backup/create`, {
        guild_id: server_id,
        backup_name: backup_name,
      })
        .then((response) => {
          if (response.data["status"]) {
            if (response.data["status"] === "success") {
              send_notification(
                "Backup erstellt",
                `Das Backup mit dem Namen "${backup_name}" wurde erfolgreich erstellt!`,
                "success",
              );
            }
            if (response.data["status"] === "already_exists") {
              send_notification(
                "Backup existiert bereits",
                `Das Backup mit dem Namen "${backup_name}" existiert bereits!`,
                "warning",
              );
            }
            if (response.data["status"] === "bad_chars") {
              send_notification(
                "Name enthält nicht erlaubte Zeichen",
                `Der Name "${backup_name}" beinhaltet nicht erlaubte Zeichen!`,
                "warning",
              );
            }
            if (response.data["status"] === "error_unknown") {
              send_notification(
                "Backup konnte nicht erstellt werden",
                `Das Backup mit dem Namen "${backup_name}" konnte nicht erstellt werden!`,
                "danger",
              );
            }
          }
        });
    } else {
      send_notification(
        "Kein Name",
        `Bitte gebe einen Namen für das Backup ein!`,
        "danger",
      );
    }
  }

  if (loading) {
    return <Loading />;
  }
  if (loadingguilds) {
    return (
      <div>
        <div className="container mx-auto">
          <div className="grid grid-cols-2">
            <div className="flex mt-10 justify-start items-center">
              <div>
                <div
                  className="transition duration-500 transform bg-gray-700 hover:bg-gray-600 rounded p-2 mr-3 flex items-center cursor-pointer"
                  onClick={() => window.location.href = "/"}
                >
                  <div>
                    <img
                      src={"https://cdn.discordapp.com/app-icons/1008785680241000498/cb6092329acb3301018dadea7017c9f2.png?size=256"}
                      alt="Logo"
                      className="rounded-full mr-3 border-2 border-white"
                      width={40}
                    />
                  </div>
                  <div>
                    <h1 className="text-2xl text-white">
                      JBackup
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex mt-10 justify-end items-center">
              <div>
                <div className="rounded p-2 bg-gray-700 mr-3 flex items-center">
                  <div>
                    <img
                      src={user?.avatar_url}
                      alt="User Avatar"
                      className="rounded-full mr-3 border-2 border-white"
                      width={40}
                    />
                  </div>
                  <div>
                    <h1 className="text-2xl text-white">
                      {user?.username}#{user?.discriminator}
                    </h1>
                  </div>
                </div>
              </div>
              <div>
                <button
                  className="transition duration-500 transform bg-red-500 hover:bg-red-600 text-white p-1 rounded mr-3"
                  onClick={() => window.location.href = "/logout"}
                >
                  Logout
                </button>
              </div>
              <div>
                <button
                  className="transition duration-500 transform bg-blue-500 hover:bg-blue-600 text-white p-1 rounded mr-3"
                  onClick={() => window.location.href = "/backups"}
                >
                  Backups
                </button>
              </div>
              <div>
                <button
                  className="transition duration-500 transform bg-blue-500 hover:bg-blue-600 text-white p-1 rounded"
                  onClick={() => window.location.href = "/guilds"}
                >
                  Server
                </button>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-center mt-20">
            <div className="h-3/4">
              <h1 className="text-white text-4xl mb-10 text-center">Server</h1>
              <div className="pt-10 pb-16 h-64 grid grid-cols-4 gap-10">
                <div className="bg-gray-700 rounded text-white mb-4 animate-pulse p-3">
                  <div className="flex justify-center">
                    <div className="h-48 w-48 rounded-full bg-gray-400 m-5 mb-3">
                    </div>
                  </div>
                  <div className="pt-4 flex items-center justify-center">
                    <div className="h-5 w-40 bg-gray-400 rounded"></div>
                  </div>

                  <div className="pt-2 flex items-center justify-center">
                    <div className="h-5 w-20 bg-gray-400 rounded"></div>
                  </div>

                  <div className="pt-2 flex items-center justify-center">
                    <div className="h-7 w-60 bg-gray-400 rounded mb-4"></div>
                  </div>
                  <div className="flex justify-center mb-5">
                    <div className="items-center">
                      <div className="h-7 w-20 text-white bg-gray-400 rounded">
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-gray-700 rounded text-white mb-4 animate-pulse p-3">
                  <div className="flex justify-center">
                    <div className="h-48 w-48 rounded-full bg-gray-400 m-5 mb-3">
                    </div>
                  </div>
                  <div className="pt-4 flex items-center justify-center">
                    <div className="h-5 w-40 bg-gray-400 rounded"></div>
                  </div>

                  <div className="pt-2 flex items-center justify-center">
                    <div className="h-5 w-20 bg-gray-400 rounded"></div>
                  </div>

                  <div className="pt-2 flex items-center justify-center">
                    <div className="h-7 w-60 bg-gray-400 rounded mb-4"></div>
                  </div>
                  <div className="flex justify-center mb-5">
                    <div className="items-center">
                      <div className="h-7 w-20 text-white bg-gray-400 rounded">
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-gray-700 rounded text-white mb-4 animate-pulse p-3">
                  <div className="flex justify-center">
                    <div className="h-48 w-48 rounded-full bg-gray-400 m-5 mb-3">
                    </div>
                  </div>
                  <div className="pt-4 flex items-center justify-center">
                    <div className="h-5 w-40 bg-gray-400 rounded"></div>
                  </div>

                  <div className="pt-2 flex items-center justify-center">
                    <div className="h-5 w-20 bg-gray-400 rounded"></div>
                  </div>

                  <div className="pt-2 flex items-center justify-center">
                    <div className="h-7 w-60 bg-gray-400 rounded mb-4"></div>
                  </div>
                  <div className="flex justify-center mb-5">
                    <div className="items-center">
                      <div className="h-7 w-20 text-white bg-gray-400 rounded">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-700 rounded text-white mb-4 animate-pulse p-3">
                  <div className="flex justify-center">
                    <div className="h-48 w-48 rounded-full bg-gray-400 m-5 mb-3">
                    </div>
                  </div>
                  <div className="pt-4 flex items-center justify-center">
                    <div className="h-5 w-40 bg-gray-400 rounded"></div>
                  </div>

                  <div className="pt-2 flex items-center justify-center">
                    <div className="h-5 w-20 bg-gray-400 rounded"></div>
                  </div>

                  <div className="pt-2 flex items-center justify-center">
                    <div className="h-7 w-60 bg-gray-400 rounded mb-4"></div>
                  </div>
                  <div className="flex justify-center mb-5">
                    <div className="items-center">
                      <div className="h-7 w-20 text-white bg-gray-400 rounded">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-auto pb-5"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  document.title = "Server - JBackup";

  DevtoolsLoading();

  return (
    <div>
      <ReactNotifications />
      <div className="container mx-auto">
        <div className="grid grid-cols-2">
          <div className="flex mt-10 justify-start items-center">
            <div>
              <div
                className="transition duration-500 transform bg-gray-700 hover:bg-gray-600 rounded p-2 mr-3 flex items-center cursor-pointer"
                onClick={() => window.location.href = "/"}
              >
                <div>
                  <img
                    src={"https://cdn.discordapp.com/app-icons/1008785680241000498/cb6092329acb3301018dadea7017c9f2.png?size=256"}
                    alt="Logo"
                    className="rounded-full mr-3 border-2 border-white"
                    width={40}
                  />
                </div>
                <div>
                  <h1 className="text-2xl text-white">
                    JBackup
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="flex mt-10 justify-end items-center">
            <div>
              <div className="rounded p-2 bg-gray-700 mr-3 flex items-center">
                <div>
                  <img
                    src={user?.avatar_url}
                    alt="User Avatar"
                    className="rounded-full mr-3 border-2 border-white"
                    width={40}
                  />
                </div>
                <div>
                  <h1 className="text-2xl text-white">
                    {user?.username}#{user?.discriminator}
                  </h1>
                </div>
              </div>
            </div>
            <div>
              <button
                className="transition duration-500 transform bg-red-500 hover:bg-red-600 text-white p-1 rounded mr-3"
                onClick={() => window.location.href = "/logout"}
              >
                Logout
              </button>
            </div>
            <div>
              <button
                className="transition duration-500 transform bg-blue-500 hover:bg-blue-600 text-white p-1 rounded mr-3"
                onClick={() => window.location.href = "/backups"}
              >
                Backups
              </button>
            </div>
            <div>
              <button
                className="transition duration-500 transform bg-blue-500 hover:bg-blue-600 text-white p-1 rounded"
                onClick={() => window.location.href = "/guilds"}
              >
                Server
              </button>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center mt-20">
          <div className="h-3/4">
            <h1 className="text-white text-4xl mb-10 text-center">Server</h1>
            {guilds ? <div></div> : (
              <div className="justify-center mt-20">
                <h1 className="text-white text-2xl mb-5 text-center">
                  gähnende Leere
                </h1>
                <button
                  className="transition duration-500 transform bg-blue-500 hover:bg-blue-600 text-white text-3xl p-3 px-4 rounded mr-5"
                  onClick={() => window.location.href = config.INVITE_URL}
                >
                  Einladen
                </button>
              </div>
            )}
            <div className="pt-10 pb-16 h-64 grid grid-cols-4 gap-10">
              {guilds?.map((guild: Guild) => {
                return (
                  <div className="transition duration-500 transform bg-gray-700 rounded text-white mb-4">
                    <div className="flex justify-center">
                      <img
                        className="rounded-full m-5 mb-3 border-white border-2"
                        width={200}
                        src={guild.guild_icon_url}
                        alt=""
                      />
                    </div>
                    <div className="px-6 py-4">
                      <h1 className="word-break text-center text-gray-400">
                        {guild.guild_id}
                      </h1>
                      {guild.guild_owner
                        ? (
                          <h3 className="word-break text-center text-gray-400">
                            Besitzer
                          </h3>
                        )
                        : (
                          <h3 className="word-break text-center text-gray-400">
                            Bot Master
                          </h3>
                        )}
                      <h1 className="text-xl word-break text-center">
                        {guild.guild_name}
                      </h1>
                    </div>

                    <div className="flex justify-center mb-5">
                      <div className="items-center">
                        {guild.bot_on_guild
                          ? (
                            <button
                              className="transition duration-500 transform bg-blue-500 hover:bg-blue-600 text-white p-1 rounded"
                              onClick={() => {
                                create_backup(guild.guild_id);
                              }}
                            >
                              Backup Erstellen
                            </button>
                          )
                          : (
                            <button
                              className="transition duration-500 transform bg-red-500 hover:bg-red-600 text-white p-1 rounded"
                              onClick={() => {
                                window.open(
                                  `https://discord.com/oauth2/authorize?client_id=1008785680241000498&permissions=8&scope=bot%20applications.commands&guild_id=${guild.guild_id}`,
                                  `JBackup auf ${guild.guild_name} Einladen`,
                                  "width=500, height=700",
                                );
                              }}
                            >
                              Bot Hinzufügen
                            </button>
                          )}
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className="p-auto pb-5"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
