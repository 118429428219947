import React, { useEffect, useState } from "react";
import axios from "axios";
import { Status, User } from "../types";

import config from "../config.json";
import { Loading } from "../templates/Loading";
import { DevtoolsLoading } from "../templates/devtools-info";

export const Docs: React.FC = () => {
  document.title = "Laden...";

  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const accessToken = localStorage.getItem("access_token");

  axios.defaults.headers.common['Authorization'] = accessToken;

  useEffect(() => {
    if (accessToken) {
      axios.post(`${config.API_URL}/users/me`, { access_token: accessToken })
        .then((response) => {
          const user: User = response.data;
          setUser(user);
          setLoading(false);
        }).catch((e) => setLoading(false));
    } else {
      setLoading(false);
    }
  }, []);

  document.title = "Docs - JBackup";

  if (loading) {
    return <Loading />;
  }

  DevtoolsLoading();

  return (
    <div>
      <div className="container mx-auto md:container md:mx-auto h-screen">
        {!user
          ? (
            <div className="grid grid-cols-2">
              <div className="flex mt-10 justify-start items-center">
                <div>
                  <div
                    className="transition duration-500 transform bg-gray-700 hover:bg-gray-600 rounded p-2 mr-3 flex items-center cursor-pointer"
                    onClick={() => window.location.href = "/"}
                  >
                    <div>
                      <img
                        src={"https://cdn.discordapp.com/app-icons/1008785680241000498/cb6092329acb3301018dadea7017c9f2.png?size=256"}
                        alt="Logo"
                        className="rounded-full mr-3 border-2 border-white"
                        width={40}
                      />
                    </div>
                    <div>
                      <h1 className="text-2xl text-white">
                        JBackup
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex mt-10 justify-end items-center">
                <div>
                  <button
                    className="transition duration-500 transform bg-blue-500 hover:bg-blue-600 text-white text-xl p-1 px-2 rounded"
                    onClick={() => window.location.href = "/login"}
                  >
                    Einloggen mit Discord
                  </button>
                </div>
              </div>
            </div>
          )
          : (
            <div className="grid grid-cols-2">
              <div className="flex mt-10 justify-start items-center">
                <div>
                  <div
                    className="transition duration-500 transform bg-gray-700 hover:bg-gray-600 rounded p-2 mr-3 flex items-center cursor-pointer"
                    onClick={() => window.location.href = "/"}
                  >
                    <div>
                      <img
                        src={"https://cdn.discordapp.com/app-icons/1008785680241000498/cb6092329acb3301018dadea7017c9f2.png?size=256"}
                        alt="Logo"
                        className="rounded-full mr-3 border-2 border-white"
                        width={40}
                      />
                    </div>
                    <div>
                      <h1 className="text-2xl text-white">
                        JBackup
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex mt-10 justify-end items-center">
                <div>
                  <div className="rounded p-2 bg-gray-700 mr-3 flex items-center">
                    <div>
                      <img
                        src={user?.avatar_url}
                        alt="User Avatar"
                        className="rounded-full mr-3 border-2 border-white"
                        width={40}
                      />
                    </div>
                    <div>
                      <h1 className="text-2xl text-white">
                        {user?.username}#{user?.discriminator}
                      </h1>
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    className="transition duration-500 transform bg-red-500 hover:bg-red-600 text-white p-1 rounded mr-3"
                    onClick={() => window.location.href = "/logout?site=docs"}
                  >
                    Logout
                  </button>
                </div>
                <div>
                  <button
                    className="transition duration-500 transform bg-blue-500 hover:bg-blue-600 text-white p-1 rounded mr-3"
                    onClick={() => window.location.href = "/backups"}
                  >
                    Backups
                  </button>
                </div>
                <div>
                  <button
                    className="transition duration-500 transform bg-blue-500 hover:bg-blue-600 text-white p-1 rounded"
                    onClick={() => window.location.href = "/guilds"}
                  >
                    Server
                  </button>
                </div>
              </div>
            </div>
          )}

        <div className="flex mt-10">
          <div className="text-white px-10 py-5 border-2 border-white rounded">
            <div>
              <div className="transition text-3xl duration-500 hover:text-gray-400">
                <a href="#start">
                  <h1>Start</h1>
                </a>
              </div>

              <div className="transition text-2xl duration-500 pl-2 pb-4 hover:text-gray-400">
                <a href="#start_informationen">
                  <h1>Informationen</h1>
                </a>
              </div>
            </div>

            <div>
              <div className="transition text-3xl duration-500 mb-2 hover:text-gray-400">
                <a href="#befehle">
                  <h1>Befehle</h1>
                </a>
              </div>

              <div>
                <div className="transition text-2xl pl-2 duration-500 hover:text-gray-400">
                  <a href="#befehle_backup">
                    <h1>Backup</h1>
                  </a>
                </div>

                <div className="transition pl-4 duration-500 hover:text-gray-400">
                  <a href="#befehle_backup_create">
                    <h1>1. create</h1>
                  </a>
                </div>

                <div className="transition pl-4 duration-500 hover:text-gray-400">
                  <a href="#befehle_backup_list">
                    <h1>2. list</h1>
                  </a>
                </div>

                <div className="transition pl-4 duration-500 hover:text-gray-400">
                  <a href="#befehle_backup_see">
                    <h1>3. see</h1>
                  </a>
                </div>

                <div className="transition pl-4 duration-500 hover:text-gray-400">
                  <a href="#befehle_backup_export">
                    <h1>4. export</h1>
                  </a>
                </div>

                <div className="transition pl-4 duration-500 hover:text-gray-400">
                  <a href="#befehle_backup_delete">
                    <h1>5. delete</h1>
                  </a>
                </div>

                <div className="transition pl-4 duration-500 pb-2 hover:text-gray-400">
                  <a href="#befehle_backup_load">
                    <h1>6. load</h1>
                  </a>
                </div>
              </div>

              <div>
                <div className="transition pl-2 text-2xl duration-500 hover:text-gray-400">
                  <a href="#befehle_messages">
                    <h1>Messages</h1>
                  </a>
                </div>

                <div className="transition pl-4 duration-500 hover:text-gray-400">
                  <a href="#befehle_messages_save">
                    <h1>1. save</h1>
                  </a>
                </div>

                <div className="transition pl-4 duration-500 hover:text-gray-400">
                  <a href="#befehle_messages_export">
                    <h1>2. export</h1>
                  </a>
                </div>

                <div className="transition pl-4 duration-500 hover:text-gray-400">
                  <a href="#befehle_messages_list">
                    <h1>3. list</h1>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="ml-10">
            <div className="pb-100">
            </div>
          </div>
        </div>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#3b82f6"
          fill-opacity="1"
          d="M0,224L34.3,202.7C68.6,181,137,139,206,112C274.3,85,343,75,411,85.3C480,96,549,128,617,149.3C685.7,171,754,181,823,170.7C891.4,160,960,128,1029,144C1097.1,160,1166,224,1234,234.7C1302.9,245,1371,203,1406,181.3L1440,160L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"
        >
        </path>
      </svg>
    </div>
  );
};
